import React from 'react';
import { useNavigate } from 'react-router-dom';

// material-ui
import { Button, Container, Grid } from '@mui/material';

// third-party
// project-imports
import { t } from "i18next";
import MainCard from "../../molecules/MainCard";

// assets

// ============================|| JWT - LOGIN ||============================ //

export const SignupCompletedContent = () => {
    const navigate = useNavigate();

    function login() {
        navigate('/login')
    }

    return (
        <Container maxWidth="sm">
            <MainCard title={t('fields.signup.single.title')}
                      sx={{}}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        {t('signup.completed')}
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" onClick={login}>Login</Button>
                    </Grid>
                </Grid>
            </MainCard>
        </Container>
    );
};
