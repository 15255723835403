import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getDashboardInfo, getIssueCounts } from './DashboardApi';

export interface DashboardInfo {
    numberOfUsers:number;
    numberOfFeedbackResponses:number;
    numberOfOpenDisruptions:number;
    numberOfIssues:number;
}

export interface IssueCount {
    label: string;
    count: number;
}

export interface DashboardState {
    loadExecuted: boolean;
    loading: boolean;
    error: string | undefined;
    dashboardInfo: DashboardInfo | undefined;
    issueCounts: IssueCount[];
}

const INITIAL_STATE: DashboardState = {
    loadExecuted: false,
    loading: false,
    error: undefined,
    dashboardInfo: { numberOfUsers: 0, numberOfFeedbackResponses: 0, numberOfOpenDisruptions: 0, numberOfIssues: 0 },
    issueCounts: []
};

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: INITIAL_STATE,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getDashboardInfo.pending.type, (state: DashboardState, action) => {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        });
        builder.addCase(getDashboardInfo.fulfilled.type, (state: DashboardState, action: PayloadAction<DashboardInfo>) => {
            return {
                ...state,
                loadExecuted: true,
                loading: false,
                error: undefined,
                dashboardInfo: Object.assign({}, action.payload),
            };
        });
        builder.addCase(getDashboardInfo.rejected.type, (state: DashboardState, action: any) => {
            return {
                ...state,
                loadExecuted: true,
                loading: false,
                error: action.error.message,
            };
        });
        builder.addCase(getIssueCounts.pending.type, (state: DashboardState, action) => {
            return {
                ...state,
                loading: true,
                error: undefined
            };
        });
        builder.addCase(getIssueCounts.fulfilled.type, (state: DashboardState, action: PayloadAction) => {
            return {
                ...state,
                loadExecuted: true,
                loading: false,
                error: undefined,
                issueCounts: Object.assign([], action.payload),
            };
        });
        builder.addCase(getIssueCounts.rejected.type, (state: DashboardState, action: any) => {
            return {
                ...state,
                loadExecuted: true,
                loading: false,
                error: action.error.message,
            };
        });
    },
});

