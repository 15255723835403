import React, { FC } from 'react';
import { authSelector, logout, useAppDispatch } from "../../../state";
import { useSelector } from 'react-redux';
import { PrimaryButton, TitleText } from '../../atoms';
import styled from 'styled-components';
import { ErrorAlert } from '../../molecules';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';


interface Props {
}


export const AuthenticationExpiredContent: FC<Props> = () => {
    const dispatch = useAppDispatch();
    const authState = useSelector(authSelector);
    const navigate = useNavigate();

    function login() {
        dispatch(logout({}))
        .then(() => {
            navigate('/');
        })
    }

    return (
        <LoginScreen>
            <ErrorAlert
                errorMessage={t('error.retrievingData')}
                errorCode={authState.errorCode}
                show={authState.error !== undefined}/>
            <TitleText>U bent uitgelogd omdat uw sessie is verlopen.</TitleText>
            <PrimaryButton className="mt-3 default float-right" disabled={false} onClick={login}>Login</PrimaryButton>
        </LoginScreen>
    )


};

const LoginScreen = styled.div`
  display: table;
  margin: auto;
  padding: 30px;
`;
