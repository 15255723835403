import React, { FC } from 'react';
import { t } from 'i18next';
import { Grid, ListItemText, MenuItem, Select } from '@mui/material';
import { getAppTheme } from "../AppTheme";
import StarRatings from "react-star-ratings";
import { IconFrowningFace, IconNeutralFace, IconSmillingFace, IconThumbsDown, IconThumbsUp } from "./icons";

interface Props {
    selectedQuestionType?: string;
    onChange: (questionType: string) => void;
}

export const QuestionTypeSelect: FC<Props> = (props) => {

    const questionType = props.selectedQuestionType ? props.selectedQuestionType : 'FIVE_STARS';

    function onSelectItem(value: string) {
        props.onChange(value);
    }
    return (
        <Select value={questionType}
                onChange={e => onSelectItem(e.target.value)}>
            <MenuItem key='FIVE_STARS' value='FIVE_STARS'>
                <Grid container direction="row" alignItems="center" height={30}>
                    <ListItemText primary={t('feedback.form.question.questionTypes.FIVE_STARS')}/>
                    <StarRatings
                        starDimension="25px"
                        rating={5}
                        numberOfStars={5}
                        starRatedColor={getAppTheme().stars.ratedColor}
                        starHoverColor={getAppTheme().stars.hoverColor}/>
                </Grid>
            </MenuItem>
            <MenuItem key='THUMBS' value='THUMBS'>
                <Grid container direction="row" alignItems="center" height={30}>
                    <ListItemText
                        primary={t('feedback.form.question.questionTypes.THUMBS')}/>
                    <Grid item>
                        <IconThumbsUp size="30"/>
                        <IconThumbsDown size="30"/>
                    </Grid>

                </Grid>
            </MenuItem>
            <MenuItem key='CHOICE' value='CHOICE'>
                <Grid container direction="row" alignItems="center" height={30}>
                    <ListItemText
                        primary={t('feedback.form.question.questionTypes.CHOICE')}/>
                </Grid>
            </MenuItem>
            <MenuItem key='DESCRIPTION' value='DESCRIPTION'>
                <Grid container direction="row" alignItems="center" height={30}>
                    <ListItemText
                        primary={t('feedback.form.question.questionTypes.DESCRIPTION')}/>
                </Grid>
            </MenuItem>
            <MenuItem key='SMILEY' value='SMILEY'>
                <Grid container direction="row" alignItems="center" height={30}>
                    <ListItemText
                        primary={t('feedback.form.question.questionTypes.SMILEY')}/>
                    <Grid item>
                        <IconSmillingFace size="30"/>
                        <IconNeutralFace size="30"/>
                        <IconFrowningFace size="30"/>
                    </Grid>
                </Grid>
            </MenuItem>
        </Select>
    )
};


