import React, { FC, useEffect } from 'react';
import { authSelector, formatDateTimeForDisplay, getIssue, issueSelector, useAppDispatch } from "../../../state";
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ErrorAlert, LoadingContainer } from '../../molecules';
import { Container } from 'react-bootstrap';
import { MailLink, PrimaryButton } from '../../atoms';
import { t } from 'i18next';
import MainCard from '../../molecules/MainCard';
import { Grid, List, ListItem, Stack, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';


export const IssueDetailContent: FC = () => {
    //table state
    const dispatch = useAppDispatch();
    let navigate = useNavigate();
    const issueState = useSelector(issueSelector);
    let authState = useSelector(authSelector);
    let {issueId} = useParams();
    const theme = useTheme();
    const matchDownMD = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        dispatch(getIssue({jwtToken: authState.jwtToken!, issueId: issueId!}))
    }, [dispatch, authState.jwtToken, issueId]);

    return (
        <Container>
            <ErrorAlert
                errorMessage={t('error.retrievingData')}
                show={issueState.error !== undefined}/>
            <LoadingContainer isLoading={issueState.loading} hasItems={(issueState.singleItem !== undefined) && (issueState.singleItem?.id.toString() === issueId)}>
                <MainCard title={t('fields.issue.single.typeDescription')}>
                    <List sx={{ py: 0 }}>
                        <ListItem divider={!matchDownMD}>
                           <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <Stack spacing={0.5}>
                                        <Typography color="secondary">{t('fields.service.single.name')}</Typography>
                                        <Typography>{issueState.singleItem?.service?.name}</Typography>
                                    </Stack>
                                </Grid>
                               <Grid item xs={12} md={6}>
                                   <Stack spacing={0.5}>
                                       <Typography color="secondary">{t('fields.service.single.location')}</Typography>
                                       <Typography>{issueState.singleItem?.service?.location}</Typography>
                                   </Stack>
                               </Grid>
                           </Grid>
                        </ListItem>
                        <ListItem divider={!matchDownMD}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} >
                                    <Stack spacing={0.5}>
                                        <Typography color="secondary">{t('fields.issue.single.description')}</Typography>
                                        <Typography>{issueState.singleItem?.description}</Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </ListItem>
                        <ListItem divider={!matchDownMD}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} >
                                    <Stack spacing={0.5}>
                                        <Typography color="secondary">{t('fields.issue.single.attachment')}</Typography>
                                        { issueState.singleItem?.attachment &&
                                            <img src={issueState.singleItem?.attachment} width={300} alt={''+t('fields.issue.single.attachment')}/>
                                        }
                                    </Stack>
                                </Grid>
                            </Grid>
                        </ListItem>
                        <ListItem divider={!matchDownMD}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <Stack spacing={0.5}>
                                        <Typography color="secondary">{t('fields.issue.single.reportedBy')}</Typography>
                                        <Typography><MailLink text={issueState.singleItem?.reportedBy}/></Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Stack spacing={0.5}>
                                        <Typography color="secondary">{t('fields.issue.single.reportedAt')}</Typography>
                                        <Typography>{formatDateTimeForDisplay(issueState.singleItem?.reportedAt)}</Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </ListItem>
                    </List>
                    <Grid item xs={12}>
                        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                            <PrimaryButton sx={{mt: 2}} onClick={() => navigate(-1)}>{t('generic.back')}</PrimaryButton>
                        </Stack>
                    </Grid>
                </MainCard>
            </LoadingContainer>
        </Container>
    )


};
