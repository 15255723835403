import React, { FC, useEffect, useState } from 'react';
import { authSelector, getIssueType, IssueTypeForm, issueTypeSelector, saveIssueType, useAppDispatch } from "../../../state";
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ErrorAlert } from '../../molecules';
import { Container } from 'react-bootstrap';
import { t } from 'i18next';
import { ImageInput } from '../../molecules/ImageInput';
import { Button, Grid, InputLabel, Stack, TextField } from '@mui/material';
import MainCard from '../../molecules/MainCard';


export const IssueTypeDetailContent: FC = () => {
    const [issueTypeForm, setIssueTypeForm] = useState<IssueTypeForm>(new IssueTypeForm());
    const dispatch = useAppDispatch();
    const {issueTypeId} = useParams();
    let authState = useSelector(authSelector);
    let issueTypesState = useSelector(issueTypeSelector);
    let navigate = useNavigate();

    useEffect(() => {
        if (issueTypeId) {
            dispatch(getIssueType({jwtToken: authState.jwtToken!, issueTypeId: Number(issueTypeId)}));
        }
    }, [dispatch, authState.jwtToken, issueTypeId]);

    useEffect(() => {
        if (issueTypeId && issueTypesState.singleItem && issueTypesState.singleItem.id === Number(issueTypeId)) {
            setIssueTypeForm(IssueTypeForm.from(issueTypesState.singleItem));
        }
    }, [issueTypesState.singleItem, issueTypeId]);

    const handleSetFormValue = (param: any) => {
        setIssueTypeForm(Object.assign({}, issueTypeForm, param));
    }
    function saveForm() {
        dispatch(saveIssueType({jwtToken: authState.jwtToken!, issueTypeForm: issueTypeForm}))
            .then((response) => {
                if (response.type.includes('fulfilled')) {
                    setTimeout(() => { // Use timeout the give time to update the redux store.
                        navigate('/manage/issuetypes')
                    }, 250);
                }
            });

    }

    function isFormValid() {
        return IssueTypeForm.isValid(issueTypeForm);
    }


    return (
        <Container>
            <ErrorAlert
                errorMessage={t('error.retrievingData')}
                show={issueTypesState.error !== undefined}/>
            <MainCard title={issueTypeId ? 'Wijzig issue type': 'Voeg issue type toe'}>
                <Grid item xs={12} md={8}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel htmlFor="description">{t('fields.issueType.single.description')}</InputLabel>
                                <TextField rows={1} value={issueTypeForm.description}
                                           onChange={e => handleSetFormValue({description: e.target.value})}/>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel htmlFor="logo">{t('fields.issueType.single.logo')}</InputLabel>
                                <ImageInput existingImagePreview={issueTypeForm.logo}
                                            onImageSelected={(image) => handleSetFormValue({logo: image})}
                                            aspectRatio={1}
                                            previewImageSize={100}
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                            <Button variant="outlined" color="secondary" onClick={() => navigate(-1)}>{t('generic.back')}</Button>
                            <Button variant="contained" onClick={saveForm} disabled={issueTypesState.loading || !isFormValid()}>{t('generic.save')}</Button>
                        </Stack>
                    </Grid>
                </Grid>
            </MainCard>
        </Container>
    )
};
