import React, { ReactNode, useRef, useState } from 'react';
import { useNavigate } from 'react-router';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, ButtonBase, CardContent, ClickAwayListener, Grid, Paper, Popper, Stack, Typography } from '@mui/material';

// project-imports
import ProfileTab from './ProfileTab';
import Avatar from '../../../../../atoms/Avatar';
import MainCard from '../../../../../molecules/MainCard';
import Transitions from '../../../../../atoms/Transitions';

// assets
import avatar from '../../../../../../assets/images/profile/unknown-profile.svg';
import { authSelector, logout, useAppDispatch } from '../../../../../../state';
import { useSelector } from 'react-redux';

// types
interface TabPanelProps {
  children?: ReactNode;
  dir?: string;
  index: number;
  value: number;
}

// tab panel wrapper
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`profile-tabpanel-${index}`}
      aria-labelledby={`profile-tab-${index}`}
      {...other}
      sx={{ p: 1 }}
    >
      {value === index && children}
    </Box>
  );
}

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const ProfilePage = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  let authState = useSelector(authSelector);

  function handleLogOut() {
    dispatch(logout({})).then(() => navigate('/'));
  }

  const anchorRef = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const [value] = useState(0);

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <ButtonBase
        sx={{
          p: 0.25,
          borderRadius: 1,
          '&:hover': { bgcolor: theme.palette.mode === 'dark' ? 'secondary.light' : 'secondary.lighter' },
          '&:focus-visible': {
            outline: `2px solid ${theme.palette.secondary.dark}`,
            outlineOffset: 2
          }
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Avatar alt="profile user" src={avatar} />
      </ButtonBase>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="grow" position="top-right" in={open} {...TransitionProps}>
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                minWidth: 240,
                borderRadius: 1.5
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard border={false} content={false}>
                  <CardContent sx={{ px: 2.5, pt: 3 }}>
                    <Grid container justifyContent="space-between" alignItems="center">
                      <Grid item>
                        <Stack direction="row" spacing={1.25} alignItems="center">
                          <Avatar alt="profile user" src={avatar} />
                          <Stack>
                            <Typography variant="subtitle1">{authState.emailAddress}</Typography>
                            {/*<Typography variant="body2" color="secondary">*/}
                            {/*  UI/UX Designer*/}
                            {/*</Typography>*/}
                          </Stack>
                        </Stack>
                      </Grid>
                    </Grid>
                  </CardContent>

                  {/*<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>*/}
                  {/*  <Tabs variant="fullWidth" value={value} onChange={handleChange} aria-label="profile tabs">*/}
                  {/*    <Tab*/}
                  {/*      sx={{*/}
                  {/*        display: 'flex',*/}
                  {/*        flexDirection: 'row',*/}
                  {/*        justifyContent: 'center',*/}
                  {/*        alignItems: 'center',*/}
                  {/*        textTransform: 'capitalize'*/}
                  {/*      }}*/}
                  {/*      icon={<Profile size={18} style={{ marginBottom: 0, marginRight: '10px' }} />}*/}
                  {/*      label="Profile"*/}
                  {/*      {...a11yProps(0)}*/}
                  {/*    />*/}
                  {/*    <Tab*/}
                  {/*      sx={{*/}
                  {/*        display: 'flex',*/}
                  {/*        flexDirection: 'row',*/}
                  {/*        justifyContent: 'center',*/}
                  {/*        alignItems: 'center',*/}
                  {/*        textTransform: 'capitalize'*/}
                  {/*      }}*/}
                  {/*      icon={<Setting2 size={18} style={{ marginBottom: 0, marginRight: '10px' }} />}*/}
                  {/*      label="Setting"*/}
                  {/*      {...a11yProps(1)}*/}
                  {/*    />*/}
                  {/*  </Tabs>*/}
                  {/*</Box>*/}
                  <TabPanel value={value} index={0} dir={theme.direction}>
                    <ProfileTab handleLogout={handleLogOut} />
                  </TabPanel>
                  {/*<TabPanel value={value} index={1} dir={theme.direction}>*/}
                  {/*  <SettingTab />*/}
                  {/*</TabPanel>*/}
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default ProfilePage;
