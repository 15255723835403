import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// material-ui
import { CircularProgress, Container, Grid } from '@mui/material';

// third-party
// project-imports
import { t } from "i18next";
import MainCard from "../../molecules/MainCard";
import { confirmSignup, signupSelector } from "../../../state/slices/signup";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../state";

// assets

// ============================|| JWT - LOGIN ||============================ //

export const SignupConfirmContent = () => {
    const navigate = useNavigate();
    const signupState = useSelector(signupSelector);
    const dispatch = useAppDispatch();

    const {signupRequestExternalKey} = useParams();
    useEffect(() => {
        if (signupRequestExternalKey) {
            if (!signupState.loading && (signupRequestExternalKey !== signupState.singleItem?.externalKey)) {
                dispatch(confirmSignup({signupRequestExternalKey}))
                    .then((response) => {
                        if (response.type.includes('fulfilled')) {
                            setTimeout(() => { // Use timeout the give time to update the redux store.
                                navigate('/signup/completed')
                            }, 250);
                        }
                    });

            }
        }
        // eslint-disable-next-line
        }, [signupState,signupRequestExternalKey]);


    return (
        <Container maxWidth="sm">
            <MainCard title={t('fields.signup.single.title')}
                      sx={{}}>
                <Grid container spacing={3}>
                    <Grid container justifyItems="center" spacing={3} m={3} mt={5}>
                        <CircularProgress sx={{marginRight: 3}}/>
                        <span>{t('signup.confirming')}</span>
                    </Grid>
                </Grid>
            </MainCard>
        </Container>
    );
};
