import React, { FC, useEffect, useMemo, useState } from 'react';
import { authSelector, deleteSupport, getServices, getSupport, saveSupport, servicesSelector, Support, SupportForm, supportSelector, useAppDispatch } from "../../../state";
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ErrorAlert, HtmlEditor, ServiceSelectCombo, SupportTypeCombo } from '../../molecules';
import { Container, Form } from 'react-bootstrap';
import { t } from 'i18next';
import { ConfirmModalPanel } from '../../molecules/ConfirmModalPanel';
import { MaterialReactTable, MRT_ColumnDef } from 'material-react-table';
import MainCard from '../../molecules/MainCard';
import { Button, Grid, InputLabel, Stack, TextField } from '@mui/material';


export const SupportDetailContent: FC = () => {
    const [supportForm, setSupportForm] = useState<SupportForm>(new SupportForm());
    const [showConfirm, setShowConfirm] = useState(false);
    const dispatch = useAppDispatch();

    const {supportId, parentId} = useParams();
    const supportState = useSelector(supportSelector);
    let navigate = useNavigate();
    let authState = useSelector(authSelector);
    const services = useSelector(servicesSelector);

    useEffect(() => {
        dispatch(getServices({jwtToken: authState.jwtToken!}));
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (supportId) {
            dispatch(getSupport({jwtToken: authState.jwtToken!, supportId: supportId}));
        }
    // eslint-disable-next-line
    }, [supportId, authState.jwtToken]);

    useEffect(() => {
        if (supportState.singleItem && supportState.singleItem.id === Number(supportId)) {
            setSupportForm(SupportForm.from(supportState.singleItem));
            if (supportForm.body) {
            }
        }
        // eslint-disable-next-line
    }, [supportState.singleItem, supportId]);

    useEffect(() => {
        if (parentId) {
            setSupportForm(Object.assign({}, new SupportForm(), {parentId: parseInt(parentId!), title: '', body: ''}));
        }
    }, [parentId]);

    useEffect(() => {
        if (!authState.isTenantAdmin) {
            handleSetFormValue({allServices: false});
        }
        // eslint-disable-next-line
    },[authState])

    const handleSetFormValue = (param: any) => {
        setSupportForm(Object.assign({}, supportForm, param));
    }

    function saveForm() {
        dispatch(saveSupport({jwtToken: authState.jwtToken!, supportForm: supportForm}))
            .then((response) => {
                if (response.type.includes('fulfilled')) {
                    setTimeout(() => { // Use timeout the give time to update the redux store.
                        navigate(-1);
                    }, 250);
                }
            });
    }

    function isFormValid() {
        return SupportForm.isValid(supportForm);
    }

    function showDeleteConfirmDialog() {
        setShowConfirm(true);
    }

    function deleteForm() {
        dispatch(deleteSupport({jwtToken: authState.jwtToken!, supportId: supportForm.id!}))
            .then((response) => {
                if (response.type.includes('fulfilled')) {
                    setShowConfirm(false);
                    setTimeout(() => { // Use timeout the give time to update the redux store.
                        navigate(-1);
                    }, 250);
                }
            });
    }

    const columns = useMemo<MRT_ColumnDef<Support>[]>(
        () => [
            {
                header: t('fields.support.single.title'),
                accessorKey: 'title'
            },
            {
                header: t('fields.support.single.supportType'),
                accessorKey: 'supportType',
                minSize: 50,
                maxSize: 100
            },
        ],
        [],
    );

    function addChild() {
        navigate('/manage/support/add/' + supportId);
    }


    return (
        <Container>
            <Container>
                <ErrorAlert
                    errorMessage={t('error.retrievingData')}
                    show={supportState.error !== undefined}/>
                <MainCard title={supportId ? 'Wijzig support item': 'Voeg support item toe'}>
                    <Grid item xs={12} md={8}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Stack spacing={1.25}>
                                    <InputLabel htmlFor="service">{t('support.form.visibleToService')}</InputLabel>
                                    { authState.isTenantAdmin && (
                                        <Form.Check
                                        inline
                                        checked={supportForm.allServices}
                                        type='radio'
                                        name='selectService'
                                        label={t('support.form.allServices')}
                                        id='allServices'
                                        onChange={e => handleSetFormValue({allServices: true})}
                                    />)}
                                    <Form.Check
                                        inline
                                        checked={!supportForm.allServices}
                                        type='radio'
                                        name='selectService'
                                        label={t('support.form.singleService')}
                                        id='singleService'
                                        onChange={e => handleSetFormValue({allServices: false})}
                                    />
                                    <ServiceSelectCombo onSelect={(service) => handleSetFormValue({service: service})} disabled={supportForm.allServices}
                                                        selectedServiceExternalKey={supportForm?.service?.externalKey} services={services.list}/>

                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack spacing={1.25}>
                                    <InputLabel htmlFor="supportType">{t('fields.support.single.supportType')}</InputLabel>
                                    <SupportTypeCombo onSelect={(supportType) => handleSetFormValue({supportType: supportType})}
                                                      selectedSupportType={supportForm.supportType}/>
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack spacing={1.25}>
                                    <InputLabel htmlFor="title">{t('fields.support.single.title')}</InputLabel>
                                    <TextField type="text"
                                               value={supportForm.title}
                                               onChange={e => handleSetFormValue({title: e.target.value})}
                                               inputProps={{ maxLength: 100 }}
                                    />
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack spacing={1.25}>
                                    <InputLabel htmlFor="body">{t('fields.support.single.body')}</InputLabel>
                                    <HtmlEditor html={supportForm.body}
                                                onChange={html => handleSetFormValue({body: html})}

                                    ></HtmlEditor>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} mt={3}>
                            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                                <Button variant="outlined" color="secondary" onClick={() => navigate(-1)}>{t('generic.back')}</Button>
                                <Button variant="contained" onClick={saveForm} disabled={supportState.loading || !isFormValid()}>{t('generic.save')}</Button>
                                {supportForm.id !== undefined &&
                                    <Button variant="outlined" color="error" onClick={showDeleteConfirmDialog}
                                                   disabled={supportState.loading || supportForm.id === undefined}>{t('generic.delete')}</Button>
                                }
                                <ConfirmModalPanel title={`Weet je zeker dat je dit support item wilt verwijderen`} onConfirm={deleteForm} onHide={() => setShowConfirm(false)}
                                                   show={showConfirm}/>
                            </Stack>
                        </Grid>
                    </Grid>
                </MainCard>
            </Container>

            <Container>
                <br/>
                <MainCard title="Onderliggende support items">
                    <Grid item xs={12} md={8}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Stack spacing={1.25}>
                                <MaterialReactTable
                                columns={columns}
                                enablePagination={false}
                                data={supportForm.pages}
                                enableTopToolbar={false}
                                enableBottomToolbar={false}
                                rowCount={supportState.totalElements}
                                muiTableBodyRowProps={({row}) => ({
                                    onClick: (event) => {
                                        navigate('/manage/support/' + row.original.id);
                                    },
                                    sx: {
                                        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                                    },
                                })}
                            />
                                </Stack>
                            </Grid>
                        <Grid item xs={12}>
                            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                                <Button variant="contained" onClick={addChild} >{t('generic.add')}</Button>
                            </Stack>
                        </Grid>
                        </Grid>
                    </Grid>
                </MainCard>
            </Container>

        </Container>
    )


};
