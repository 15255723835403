import React from 'react';
import { useSelector } from 'react-redux';
import { authSelector } from '../../state';
import { Button } from '@mui/material';
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";


interface Props {
  disabled?: boolean;
  className?: string;
  onClick: () => void;
  children: string;
  sx?: SxProps<Theme>;
  onlyVisibleForTenantAdmins?: boolean;
  startIcon?: React.ReactNode;
}


export const PrimaryButton: React.FC<Props> = (props) => {
  const authState = useSelector(authSelector);
  if (props.onlyVisibleForTenantAdmins && !authState.isTenantAdmin) {
    return null;
  }
  return (
    <Button sx={props.sx} variant="contained" disabled={props.disabled} onClick={props.onClick} startIcon={props.startIcon}>
      {props.children}
    </Button>
  );
};


PrimaryButton.defaultProps = {
  onlyVisibleForTenantAdmins: false
}
