import React, { FC, useEffect, useState } from 'react';
import { authSelector, useAppDispatch } from "../../../state";
import { useSelector } from 'react-redux';
import { deleteFeedbackResults, feedbackSelector, getFeedback, getFeedbackResults, ServiceFeedbackRequest } from '../../../state/slices/feedback';
import { ErrorAlert } from '../../molecules';
import { t } from 'i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { Bar, BarChart, Tooltip, XAxis, YAxis } from 'recharts';
import { Button, CardHeader, Grid, Stack, Typography } from '@mui/material';
import MainCard from '../../molecules/MainCard';
import { ServiceFeedbackRequestSelectCombo } from '../../molecules/ServiceFeedbackRequestSelectCombo';
import { ConfirmModalPanel } from '../../molecules/ConfirmModalPanel';
import { enqueueSnackbar } from 'notistack';

interface Props {
}

export const FeedbackResultContent: FC<Props> = () => {
    let {feedbackId} = useParams();
    const [resultCount, setResultCount] = useState(0);
    const [selectedService, setSelectedService] = useState<ServiceFeedbackRequest>();
    const [showConfirm, setShowConfirm] = useState(false);
    const feedback = useSelector(feedbackSelector);
    let authState = useSelector(authSelector);
    const dispatch = useAppDispatch();
    let navigate = useNavigate();

    useEffect(() => {
        if (feedbackId) {
            dispatch(getFeedback({feedbackId: feedbackId, jwtToken: authState.jwtToken}))
        }
    }, [dispatch, authState.jwtToken, feedbackId]);

    useEffect(() => {
        if (feedbackId) {
            dispatch(getFeedbackResults({feedbackId: feedbackId, externalKey: selectedService?.externalKey, jwtToken: authState.jwtToken}))
        }
    }, [dispatch, authState.jwtToken, feedbackId, selectedService]);

    useEffect(() => {
        let firstQuestion = feedback.feedbackResults?.at(0)?.questionId;
        const count = feedback.feedbackResults.filter((result) => result.questionId === firstQuestion).reduce((total, feedbackResult) => {
            return total + feedbackResult.count;
        }, 0);
        setResultCount(count);
    }, [feedback.feedbackResults])

    const handleSelectService = (selected: ServiceFeedbackRequest) => {
        setSelectedService(selected);
    };

    const handleDelete = () => {
        setShowConfirm(true);
    }

    const handleConfirmDelete = () => {
        dispatch(deleteFeedbackResults({feedbackId: feedbackId, externalKey: selectedService?.externalKey, jwtToken: authState.jwtToken}))
            .then((response) => {
                if (response.type.includes('fulfilled')) {
                    enqueueSnackbar(t('feedback.results.deleted'), { variant: 'success' });
                }
            });
        setShowConfirm(false);
    }

    const handleHideConfirm = () => {
        setShowConfirm(false);
    }

    function back() {
        navigate('/manage/feedback/' + feedbackId);
    }

    function toAllResults() {
        navigate('/manage/feedback/' + feedbackId + '/responses');
    }

    return (
        <Container>
            <ErrorAlert
                errorMessage={t('error.retrievingData')}
                show={feedback.error !== undefined}/>
            <Grid container spacing={3}>
                <ConfirmModalPanel title={`Weet je zeker dat je deze resultaten wilt verwijderen`} onConfirm={handleConfirmDelete} onHide={handleHideConfirm} show={showConfirm}/>
                <Grid item xs={12} md={12}>
                    <MainCard title={'Feedback resultaten'}>
                        <Grid item xs={12} md={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <Stack spacing={1.25}>
                                        <Typography color="secondary">{t('feedback.form.title')}</Typography>
                                        <Typography>{feedback.feedback?.title}</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Stack spacing={1.25}>
                                        <Stack spacing={1.25}>
                                            <Typography color="secondary">{t('feedback.form.description')}</Typography>
                                            <Typography>{feedback.feedback?.description}</Typography>
                                        </Stack>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Stack spacing={1.25}>
                                        <Stack spacing={1.25}>
                                            <Typography color="secondary">Dienst</Typography>
                                            <ServiceFeedbackRequestSelectCombo onSelect={handleSelectService}
                                                                               serviceFeedbackRequests={feedback.feedback?.services}
                                                                               selectedServiceFeedbackExternalKey={selectedService?.externalKey}/>
                                        </Stack>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Stack spacing={1.25}>
                                        <Typography color="secondary">{t('feedback.results.count')}</Typography>
                                        <Typography>{resultCount}</Typography>
                                    </Stack>
                                    <Grid item><Button variant="contained" color="error" onClick={handleDelete}>Verwijder resultaten</Button></Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack spacing={0}>
                                        <CardHeader title="Vragen" />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    {
                                        feedback.feedback?.questions?.map(question => (
                                            <Col sm={"6"}>
                                                <Row>{question.title}</Row>
                                                <Row>
                                                    <Col sm="8">
                                                        { feedback.feedbackResults.filter(value => value.questionId === question.id).length > 0 ? <BarChart width={500} height={300} data={feedback.feedbackResults.filter(value => value.questionId === question.id)} margin={{
                                                            top: 10,
                                                            right: 30,
                                                            left: -20,
                                                            bottom: 5,
                                                        }}>
                                                            <XAxis dataKey="name" />
                                                            <YAxis />
                                                            <Tooltip />
                                                            <Bar dataKey="count" fill="#429488" />
                                                        </BarChart> : <Form.Label column sm="4">{t('feedback.results.noresults')}</Form.Label> }
                                                    </Col>
                                                </Row>
                                            </Col>
                                        ))
                                    }

                                </Grid>
                                <Grid container spacing={1} justifyContent="flex-end">
                                    <Grid item><Button variant="outlined" color="secondary"
                                                       onClick={back}>{t('generic.back')}</Button></Grid>
                                    <Grid item><Button variant="contained" onClick={toAllResults}>Alle resultaten</Button></Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </MainCard>
                </Grid>
            </Grid>
        </Container>
);


};
