import React, { FC, useEffect, useState } from 'react';
import { stateToHTML } from 'draft-js-export-html';
import { ContentState, convertFromHTML, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

interface Props {
    html: string | undefined
    onChange: (html: string) => void;
}

export const HtmlEditor: FC<Props> = (props) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [currentHtml, setCurrentHtml] = useState<string>();

    useEffect(() => {
            if (props.html !== currentHtml) { // Only update editor in case of changed html
                let convertFromHTML1 = convertFromHTML(props.html + '');
                const contentState = ContentState.createFromBlockArray(convertFromHTML1.contentBlocks);
                setEditorState(EditorState.createWithContent(contentState));
            }
        },
        [currentHtml, props.html])
    useEffect(() => {
        let html = stateToHTML(editorState.getCurrentContent());
        setCurrentHtml(html);
        if (html !== currentHtml) {
            props.onChange(html);
        }
    }, [props, editorState, currentHtml]);


    const toolbar = {
        options: ['inline', 'blockType', 'list', 'textAlign', 'link',],
        inline: {
            inDropdown: false,
            options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
        },
        blockType: {
            inDropdown: false,
            options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
        },
        list: {
            inDropdown: false,
            options: ['unordered', 'ordered']
        },
        history: {
            inDropdown: false,
            options: ['undo', 'redo'],
        },
    };
    return (
        <Editor
            toolbar={toolbar}
            editorState={editorState}
            onEditorStateChange={setEditorState}
            toolbarStyle={{marginBottom: '0px', borderColor: '#dbe0e5', borderTopLeftRadius: '8px', borderTopRightRadius: '8px', }}
            editorStyle={{border: 'solid 1px', borderColor: '#dbe0e5', borderTopWidth: '0px', borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px', paddingLeft:'10px', paddingRight:'10px'}}
        />
    )
};
