import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Cropper from "react-easy-crop";
import { getCroppedImg, getRotatedImage } from './canvasUtils'
import { getOrientation } from 'get-orientation/browser'
import { IconArrowClockwise, IconArrowCounterClockwise, IconZoomIn, IconZoomOut } from "../atoms";
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../atoms/PrimaryButton';
import { Area } from 'react-easy-crop/types';
import { PopupTransition } from "../atoms/Transitions";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

export interface ImageCropDialogProps {
    correlation: any
    title: string
    body: string
    showDialog: boolean
    imageFile: File | undefined
    maxHeight: number
    onConfirm: (croppedImage: File) => void;
    onCancel: () => void;
    aspectRatio?: number;
}


export const ImageCropDialog: FC<ImageCropDialogProps> = (props,
                                                          onConfirm = () => {
                                                          },
                                                          onCancel = () => {
                                                          }) => {
    const ORIENTATION_TO_ANGLE = {
        '3': 180,
        '6': 90,
        '8': -90,
    }

    const [imageSrc, setImageSrc] = useState<any>(null)
    const [crop, setCrop] = useState({x: 0, y: 0})
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null)
    const {t} = useTranslation();

    const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    useEffect(() => {
        async function loadImage(file: File) {
            let imageDataUrl = await readFile(file)
            setZoom(1);
            setRotation(0);
            // apply rotation if needed
            const orientation = await getOrientation(file)
            // @ts-ignore
            const rotation = ORIENTATION_TO_ANGLE[orientation]
            if (rotation) {
                imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
            }

            setImageSrc(imageDataUrl)
        }

        loadImage(props.imageFile!);
    }, [props.imageFile])

    async function onConfirmClicked() {
        let croppedImage = await getCroppedImg(
            imageSrc,
            croppedAreaPixels,
            rotation);
        props.onConfirm(croppedImage);
    }

    function zoomIn() {
        setZoom(Math.min(3, zoom + 0.1));
    }

    function zoomOut() {
        setZoom(Math.max(1, zoom - 0.1));
    }

    function rotateClockwise() {
        setRotation(rotation + 90);
    }

    function rotateCounterClockwise() {
        setRotation(rotation - 90);
    }

    const cropperRef = useRef<Cropper>(null);

    return (
        <Dialog
            maxWidth="sm"
            TransitionComponent={PopupTransition}
            keepMounted
            fullWidth
            open={props.showDialog}
        >
            <DialogTitle>
                {props.title}
            </DialogTitle>
            <DialogContent>
                {imageSrc &&
                    <CropContainer>
                        <Cropper
                            ref={cropperRef}
                            style={{containerStyle: {marginTop: '55px', marginBottom: '55px'}}}
                            image={imageSrc}
                            crop={crop}
                            rotation={rotation}
                            zoom={zoom}
                            aspect={props.aspectRatio}
                            onCropChange={setCrop}
                            zoomWithScroll={true}
                            onRotationChange={setRotation}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                            onMediaLoaded={(mediaSize) => {
                                // Needed otherwise the initial croparea is not shown.
                                setTimeout(() => {
                                    if (cropperRef.current) {
                                        cropperRef.current.computeSizes()
                                        cropperRef.current.recomputeCropPosition()
                                    }
                                }, 300);
                            }}
                        />
                    </CropContainer>
                }
            </DialogContent>
            <DialogActions className="d-flex justify-content-between">
                <span>
                    <Button type="button" className="btn btn-labeled btn-light" onClick={zoomIn}>
                        <span className="btn-label"><IconZoomIn size={"16"}/></span>
                    </Button>
                    <Button type="button" className="btn btn-labeled btn-light" onClick={zoomOut}>
                        <span className="btn-label"><IconZoomOut size={"16"}/></span>
                    </Button>
                    <Button type="button" className="btn btn-labeled btn-light" onClick={rotateClockwise}>
                        <span className="btn-label"><IconArrowClockwise size={"16"}/></span>
                    </Button>
                    <Button type="button" className="btn btn-labeled btn-light" onClick={rotateCounterClockwise}>
                        <span className="btn-label"><IconArrowCounterClockwise size={"16"}/></span>
                    </Button>
                </span>
                <span>
                    <PrimaryButton className="mr-1" onClick={onConfirmClicked}>{t('generic.ok')}</PrimaryButton>
                    <Button onClick={props.onCancel}>{t('generic.cancel')}</Button>
                </span>
            </DialogActions>
        </Dialog>
    )
}

function readFile(file: File) {
    return new Promise((resolve) => {
        const reader = new FileReader()
        reader.addEventListener('load', () => resolve(reader.result), false)
        reader.readAsDataURL(file)
    })
}

const CropContainer = styled.div`
  min-height: 50vh;
  // min-width: 50vh;
  color: white;
  background-color: white;
`;

ImageCropDialog.defaultProps = {
    aspectRatio: 0.71
};
