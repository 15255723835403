import React, { FC, useEffect, useMemo, useState } from 'react';
import { authSelector, formatDateTimeForDisplay, getIssues, getServices, Issue, issuesSelector, servicesSelector, toNullableDate, useAppDispatch } from "../../../state";
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import { IconAttachment } from '../../atoms';
import { ErrorAlert } from '../../molecules';
import { t } from 'i18next';
import type { MRT_ColumnDef } from 'material-react-table';
import { MaterialReactTable } from 'material-react-table';
import type { ColumnFiltersState, PaginationState, SortingState, } from '@tanstack/react-table';
import { getSortParameters, OrderEnum } from '../../utils';
import { useNavigate, useParams } from 'react-router-dom';


interface Props {
}

export const IssuesListContent: FC<Props> = () => {
    //table state
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState<SortingState>([]);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });
    let {serviceExternalKey, reportedAtFrom, reportedAtUntil} = useParams();
    const dispatch = useAppDispatch();
    let navigate = useNavigate();
    const issuesState = useSelector(issuesSelector);
    let authState = useSelector(authSelector);
    let servicesState = useSelector(servicesSelector);


    let title = t('fields.issue.plural.typeDescription');
    if (serviceExternalKey) {
        let selectedService = servicesState.list.filter(service => service.externalKey === serviceExternalKey).shift();
        if (selectedService) {
            title = title + ' van ' + selectedService.name + ((selectedService.location.length > 0) ? ', ' + selectedService.location : '');
        }
    }

    const columns = useMemo<MRT_ColumnDef<Issue>[]>(
        () => [
            {
                header: 'Id',
                accessorKey: 'id',
            },
            {
                header: 'Datum melding',
                id: 'reportedAt',
                accessorFn: (originalRow) => formatDateTimeForDisplay(new Date(originalRow.reportedAt)),
            },
            {
                header: 'Dienst',
                accessorKey: 'service.name',
            },
            {
                header: 'Locatie',
                accessorKey: 'service.location',
            },
            {
                header: 'Problemen',
                accessorKey: 'issueTypeDescription',
            },
            {
                header: 'Impact',
                accessorKey: 'impactTypeDescription',
            },
            {
                header: 'Gemeld door',
                accessorKey: 'reportedBy',
            },
            {
                header: 'Foto',
                accessorFn: (originalRow) => (originalRow.hasAttachment) ? <IconAttachment size="24"/> : '',
                enableSorting: false
            },
        ],
        [],
    );
    useEffect(() => {
        dispatch(getServices({jwtToken: authState.jwtToken!}));
    }, [dispatch, authState.jwtToken]);
    useEffect(() => {
        let sortParameters = getSortParameters(sorting, {field: 'reportedAt', order: OrderEnum.desc});

        dispatch(getIssues({
            jwtToken: authState.jwtToken!,
            page: pagination.pageIndex,
            pageSize: pagination.pageSize,
            globalSearchText: globalFilter, sortParameters,
            serviceExternalKey: serviceExternalKey,
            reportedAtFrom: toNullableDate(reportedAtFrom),
            reportedAtUntil: toNullableDate(reportedAtUntil)
        }))
    }, [
        dispatch, authState.jwtToken,
        columnFilters,
        globalFilter,
        pagination.pageIndex,
        pagination.pageSize,
        sorting,
        serviceExternalKey,
        reportedAtFrom,
        reportedAtUntil
    ]);
    return (
        <Container>
            <ErrorAlert
                errorMessage={t('error.retrievingData')}
                show={issuesState.error !== undefined}/>

            <MaterialReactTable
                columns={columns}
                data={issuesState.list}
                manualFiltering
                manualPagination
                manualSorting
                enableRowSelection={false}
                enableColumnOrdering
                enableGlobalFilter={true}
                enableColumnFilters={false}
                onColumnFiltersChange={setColumnFilters}
                onGlobalFilterChange={setGlobalFilter}
                onPaginationChange={setPagination}
                onSortingChange={setSorting}
                rowCount={issuesState.totalElements}
                state={{
                    columnFilters,
                    globalFilter,
                    pagination,
                    sorting
                }}
                muiTableBodyRowProps={({row}) => ({
                    onClick: (event) => {
                        navigate('/dashboard/issues/' + row.original.id);
                    },
                    sx: {
                        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                    },
                })}
            />
        </Container>
    )


};
