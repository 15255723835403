import React, { FC, useEffect } from 'react';
import { authSelector, getDashboardInfo, useAppDispatch } from "../../../state";
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import { ErrorAlert } from '../../molecules';
import { t } from 'i18next';
import { dashboardSelector } from '../../../state/slices/dashboard';
import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Flash, Star1, TicketStar, User } from 'iconsax-react';
import HoverReportCard from '../../molecules/HoverReportCard';
import IssuesGraph from './IssuesGraph';

interface Props {
}

export const DashboardContent: FC<Props> = () => {
    const dispatch = useAppDispatch();
    let authState = useSelector(authSelector);
    let dashboardState = useSelector(dashboardSelector);
    const theme = useTheme();

    useEffect(() => {
        dispatch(getDashboardInfo({jwtToken: authState.jwtToken!}));
    }, [dispatch, authState.jwtToken]);

    return (
        <Container>
            <ErrorAlert
                errorMessage={t('error.retrievingData')}
                show={dashboardState.error !== undefined}/>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4} lg={3} sm={6}>
                    <HoverReportCard primary="Gebruikers" secondary={'' + dashboardState.dashboardInfo?.numberOfUsers} iconPrimary={User} color={theme.palette.primary.main} />
                </Grid>
                <Grid item xs={12} md={4} lg={3} sm={6}>
                    <HoverReportCard
                        primary="Feedback antwoorden"
                        secondary={'' + dashboardState.dashboardInfo?.numberOfFeedbackResponses}
                        iconPrimary={Star1}
                        color={theme.palette.info.main} />
                </Grid>
                <Grid item xs={12} md={4} lg={3} sm={6}>
                    <HoverReportCard
                        primary="Meldingen" secondary={'' + dashboardState.dashboardInfo?.numberOfIssues} iconPrimary={TicketStar}
                        color={theme.palette.mode === 'dark' ? theme.palette.secondary[200] : theme.palette.secondary.dark}
                    />
                </Grid>
                <Grid item xs={12} md={4} lg={3} sm={6}>
                    <HoverReportCard primary="Verstoringen" secondary={'' + dashboardState.dashboardInfo?.numberOfOpenDisruptions} iconPrimary={Flash} color={theme.palette.error.main} />
                </Grid>

                <Grid item xs={12}>
                    <IssuesGraph/>
                </Grid>
            </Grid>
        </Container>
    )
};
