import React, { FC, useEffect } from 'react';
import { useAppDispatch } from "../../../../state";
import { useSelector } from 'react-redux';
import { feedbackSelector, getFeedbackByKey } from '../../../../state/slices/feedback';
import { TitleText } from '../../../atoms';
import { ErrorAlert } from '../../../molecules';
import { t } from 'i18next';
import { useParams } from 'react-router-dom';
import { getAppTheme } from '../../../AppTheme';
import styled from 'styled-components';
import { Grid } from "@mui/material";

interface Props {
}

export const FeedbackRequestSentContent: FC<Props> = () => {
    let {feedbackKey} = useParams();
    const dispatch = useAppDispatch();
    const feedback = useSelector(feedbackSelector);
    useEffect(() => {
        dispatch(getFeedbackByKey({feedbackKey: feedbackKey}));
    }, [dispatch, feedbackKey]);

    return (
        <Grid container>
            <Grid item xs={0} sm={1} md={1}></Grid>
            <Grid container xs={12} sm={10} md={10}>
                <ErrorAlert
                    errorMessage={t('error.retrievingData')}
                    show={feedback.error !== undefined}/>
                <Grid container spacing={3}>
                    <HeaderItem item xs={12} ml={3}>
                        <TitleText>Feedback {feedback.feedback?.service?.name}</TitleText>
                    </HeaderItem>
                    <HeaderItem item xs={12} ml={3} pb={5}>
                        {'Bedankt voor je feedback over deze ' + feedback.feedback?.service?.name}
                    </HeaderItem>

                    <Grid item ml={3} mr={3}>
                        {feedback.feedback?.description}
                    </Grid>
                    <Grid item ml={3} mr={3}>
                        <img src={feedback.feedback?.image} width={300} alt={"feedback"}/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={0} sm={1} md={1}></Grid>
        </Grid>
    )
};

const HeaderItem = styled(Grid)`
  background-color: ${getAppTheme().colors.tint2};
  color: ${getAppTheme().colors.primary};
  font-family: ${getAppTheme().generic.fontFamily};
`;
