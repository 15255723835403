import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, authSelector, getAuthCachedState } from '../../state';

const Authenticated: FC<PropsWithChildren> = (props) => {
    return (<div>
        {props.children}
    </div>);
};

const UnAuthenticated: FC<PropsWithChildren> = (props) => {
    return (<div>
        {props.children}
    </div>);
};
const AuthenticationExpired: FC<PropsWithChildren> = (props) => {
    return (<div>
        {props.children}
    </div>);
};

const Loading: FC<PropsWithChildren> = (props) => {
    return (<div>
        {props.children}
    </div>);
};

const Error: FC<PropsWithChildren> = (props) => {
    return (<div>
        {props.children}
    </div>);
};

type MenuSubComponents = {
    Authenticated: typeof Authenticated
    UnAuthenticated: typeof UnAuthenticated
    AuthenticationExpired: typeof AuthenticationExpired
    Loading: typeof UnAuthenticated
    Error: typeof UnAuthenticated
}
const AuthenticationStateHandler: FC<PropsWithChildren> & MenuSubComponents = (props) => {
    const [initalLoadExecuted, setInitalLoadExecuted] = useState(false);
    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        dispatch(getAuthCachedState({}))
            .finally(() => {
                setInitalLoadExecuted(true);});
    }, [dispatch]);
    const authState = useSelector(authSelector);
    function getChildElement(elementType: React.FC<React.PropsWithChildren>) {
        return React.Children.map(props.children, child => {
                // @ts-ignore
            if (React.isValidElement(child) && (child as React.ReactElement<any>).type.name === elementType.name) {
                    let selectedChild = child as React.ReactElement<any>;
                    // Modifying here
                    return selectedChild;
                }
            },
        );
    }


    var componentToShow;
    if (!initalLoadExecuted && (authState.loading || !authState.loadExecuted)) {
        componentToShow = getChildElement(Loading);
    } else if (!initalLoadExecuted && (authState.error !== undefined)) {
        componentToShow = getChildElement(Error);
    } else if (initalLoadExecuted && (authState.jwtTokenExpired)) {
        componentToShow = getChildElement(AuthenticationExpired);
    } else {
        componentToShow = getChildElement(authState.authenticated ? Authenticated : UnAuthenticated);
    }

    return (<div>
        {componentToShow}
    </div>);


};

AuthenticationStateHandler.Authenticated = Authenticated;
AuthenticationStateHandler.UnAuthenticated = UnAuthenticated;
AuthenticationStateHandler.AuthenticationExpired = AuthenticationExpired;
AuthenticationStateHandler.Loading = Loading;
AuthenticationStateHandler.Error = Error;
export default AuthenticationStateHandler;
