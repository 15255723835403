import React, { FC, useEffect, useState } from 'react';
import { authSelector, useAppDispatch } from "../../../state";
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import { IconExcel, TitleText } from '../../atoms';
import { ErrorAlert } from '../../molecules';
import { t } from 'i18next';
import type { MRT_ColumnDef } from 'material-react-table';
import { MaterialReactTable } from 'material-react-table';
import { FeedbackResponse, feedbackSelector, getFeedback, getFeedbackResponses, getFeedbackResponsesAsExcel, ServiceFeedbackRequest } from '../../../state/slices/feedback';
import { useNavigate, useParams } from 'react-router-dom';
import { PrimaryButton } from '../../atoms/PrimaryButton';
import { getThumbsElement } from "../../atoms/ThumbsGroupSelect";
import { getSmileyElement } from "../../atoms/SmileyGroupSelect";
import StarRatings from "react-star-ratings";
import { getAppTheme } from "../../AppTheme";
import { Grid, MenuItem, Select } from "@mui/material";


interface Props {
}

export const FeedbackResultListContent: FC<Props> = () => {
    let {feedbackId} = useParams();
    const [selectedService, setSelectedService] = useState<ServiceFeedbackRequest>();
    const [columns, setColumns] = useState<MRT_ColumnDef<FeedbackResponse>[]>([
        {
            header: 'ID',
            accessorKey: 'id',
        }]);
    const dispatch = useAppDispatch();
    const feedback = useSelector(feedbackSelector);
    let authState = useSelector(authSelector);
    let navigate = useNavigate();


    function getTextAnswer(row: FeedbackResponse, questionNumber: number) {
        let question = feedback.feedback?.questions![questionNumber];
        let answer = row.answers.find((answer) => answer.questionId === question?.id);
        if (question?.questionType === 'THUMBS') {
            return getThumbsElement(answer?.rating, "32");
        } else if (question?.questionType === 'SMILEY') {
            return getSmileyElement(answer?.rating, "32");
        } else if (question?.questionType === 'FIVE_STARS') {
            return (<StarRatings rating={answer?.rating} starDimension="32"
                                 starRatedColor={getAppTheme().stars.ratedColor}/>);
        } else if (question?.questionType === 'CHOICE') {
            return question?.possibleAnswers?.find((pa) => pa.answer === answer?.rating)?.label;
        } else if (question?.questionType === 'DESCRIPTION') {
            return answer?.text;
        }
        return answer?.rating;
    }

    useEffect(() => {
        if (feedbackId) {
            dispatch(getFeedback({feedbackId: feedbackId, jwtToken: authState.jwtToken}))
        }
    }, [feedbackId, dispatch, authState.jwtToken]);

    useEffect(() => {
        let columns: MRT_ColumnDef<FeedbackResponse>[] = [];
        if (feedback.feedback && feedback.feedback.questions && feedback.feedback.questions.length > 0) {
            for (let i = 0; i < feedback.feedback!.questions!.length; i++) {
                columns.push(
                    {
                        header: (i + 1) + '. ' + feedback.feedback?.questions![i].title,
                        accessorFn: (row) => getTextAnswer(row, i)
                    }
                );
            }
        }
        setColumns(columns);
    }, [feedback.feedback]);

    useEffect(() => {
        dispatch(getFeedbackResponses({
            feedbackId: feedbackId,
            externalKey: selectedService?.externalKey,
            jwtToken: authState.jwtToken
        }));
    }, [feedbackId, dispatch, selectedService, authState.jwtToken]);

    function onSelectItem(externalKey: string) {
        setSelectedService(feedback.feedback?.services?.find((service) => service.externalKey === externalKey));
    }

    function back() {
        navigate('/manage/feedback/' + feedbackId + '/results');
    }

    function downloadAsExcel() {
        dispatch(getFeedbackResponsesAsExcel({
            feedbackId: feedbackId,
            externalKey: selectedService?.externalKey,
            jwtToken: authState.jwtToken
        }));

    }

    return (
        <Container>
            <TitleText>Feedback resultaten</TitleText>
            <ErrorAlert
                errorMessage={t('error.retrievingData')}
                show={feedback.error !== undefined}/>
            {(feedback.feedback?.services) && (feedback.feedback?.services?.length > 1) && (
                <Grid container alignItems="center" sx={{mb:2}}>
                    <Grid item sx={{mr:2}}>
                        Dienst
                    </Grid>
                    <Grid item>
                        <Select
                            value={(selectedService?.externalKey === undefined) ? "all" : selectedService?.externalKey}
                            onChange={e => onSelectItem(e.target.value)}>
                            <MenuItem key="all" value="all">Alle diensten</MenuItem>
                            {feedback.feedback?.services?.map((service) => (
                                <MenuItem key={service.externalKey}
                                          value={service.externalKey}>{service.service.name}{service.service.location ? ' - ' + service.service.location : null}</MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Grid>

            )}

            <MaterialReactTable
                columns={columns}
                data={feedback.feedbackResponses}
                enableRowSelection={false}
                enableGlobalFilter={false}
                enableColumnFilters={false}
                enableColumnOrdering={false}
                enableFullScreenToggle={false}
                enableDensityToggle={false}
                enableHiding={false}
                enableTopToolbar={false}
                enableSorting={false}
                enableColumnActions={false}

            />
            <PrimaryButton onClick={back} sx={{mt: 2}}>{t('generic.back')}</PrimaryButton>
            <PrimaryButton onClick={downloadAsExcel} sx={{ml:2, mt: 2}} startIcon={<IconExcel size="64"/>}>{t('generic.downloadAsExcel')}</PrimaryButton>
        </Container>
    );


};
