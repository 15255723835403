import isEmail from "validator/lib/isEmail";

export class SignupForm {
    companyName: string = '';
    contactPersonName: string = '';
    contactPersonEmailAddress: string = '';
    subscriptionType: string = '';

    static isValid(signupForm: SignupForm | undefined): boolean {
        if (signupForm === undefined) {
            return false;
        }

        function containsValue(value: any) {
            return value !== undefined && value !== null && (value.length === undefined || value.length > 0);
        }

        if (!containsValue(signupForm.companyName)) {
            return false;
        }

        if (!containsValue(signupForm.contactPersonName)) {

            return false;
        }
        if (!containsValue(signupForm.contactPersonEmailAddress)) {
            return false;
        }
        if (!isEmail(signupForm.contactPersonEmailAddress)) {
            return false;
        }

        if (!containsValue(signupForm.subscriptionType)) {
            return false;
        }

        return true;
    }

}

