import React, { FC, useEffect, useState } from 'react';
import { authSelector, getImpactType, ImpactTypeForm, impactTypeSelector, saveImpactType, useAppDispatch } from "../../../state";
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ErrorAlert } from '../../molecules';
import { Container } from 'react-bootstrap';
import { t } from 'i18next';
import { ImageInput } from '../../molecules/ImageInput';
import { Button, Grid, InputLabel, Stack, TextField } from '@mui/material';
import MainCard from '../../molecules/MainCard';


export const ImpactTypeDetailContent: FC = () => {
    const [impactTypeForm, setImpactTypeForm] = useState<ImpactTypeForm>(new ImpactTypeForm());
    const dispatch = useAppDispatch();
    const {impactTypeId} = useParams();
    let authState = useSelector(authSelector);
    let impactTypesState = useSelector(impactTypeSelector);
    let navigate = useNavigate();

    useEffect(() => {
        if (impactTypeId) {
            dispatch(getImpactType({jwtToken: authState.jwtToken!, impactTypeId: Number(impactTypeId)}));
        }
    }, [dispatch, authState.jwtToken, impactTypeId]);

    useEffect(() => {
        if (impactTypeId && impactTypesState.singleItem && impactTypesState.singleItem.id === Number(impactTypeId)) {
            setImpactTypeForm(ImpactTypeForm.from(impactTypesState.singleItem));
        }
    }, [impactTypesState.singleItem, impactTypeId]);

    const handleSetFormValue = (param: any) => {
        setImpactTypeForm(Object.assign({}, impactTypeForm, param));
    }

    function saveForm() {
        dispatch(saveImpactType({jwtToken: authState.jwtToken!, impactTypeForm: impactTypeForm}))
            .then((response) => {
                if (response.type.includes('fulfilled')) {
                    setTimeout(() => { // Use timeout the give time to update the redux store.
                        navigate('/manage/impacttypes')
                    }, 250);
                }
            });

    }

    function isFormValid() {
        return ImpactTypeForm.isValid(impactTypeForm);
    }


    return (
        <Container>
            <ErrorAlert
                errorMessage={t('error.retrievingData')}
                show={impactTypesState.error !== undefined}/>
            <MainCard title={impactTypeId ? 'Wijzig impact type': 'Voeg impact type toe'}>
                <Grid item xs={12} md={8}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel htmlFor="description">{t('fields.impactType.single.description')}</InputLabel>
                                <TextField rows={1} value={impactTypeForm.description}
                                           onChange={e => handleSetFormValue({description: e.target.value})}/>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel htmlFor="logo">{t('fields.impactType.single.logo')}</InputLabel>
                                <ImageInput existingImagePreview={impactTypeForm.logo}
                                            onImageSelected={(image) => handleSetFormValue({logo: image})}
                                            aspectRatio={1}
                                            previewImageSize={100}
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                            <Button variant="outlined" color="secondary" onClick={() => navigate(-1)}>{t('generic.back')}</Button>
                            <Button variant="contained" onClick={saveForm} disabled={impactTypesState.loading || !isFormValid()}>{t('generic.save')}</Button>
                        </Stack>
                    </Grid>
                </Grid>
            </MainCard>
        </Container>
    )


};
