import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { addLocation, authSelector, deleteLocation, getLocations, Location, locationSelector, updateLocation, useAppDispatch } from "../../../state";
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import { t } from 'i18next';
import type { MRT_ColumnDef } from 'material-react-table';
import { MaterialReactTable, MRT_Cell, MRT_Row, MRT_TableOptions } from 'material-react-table';
import { Box, IconButton, Tooltip } from '@mui/material';
import { ConfirmModalPanel } from '../../molecules/ConfirmModalPanel';
import { PrimaryButton } from '../../atoms/PrimaryButton';
import { CreateNewLocationModalPanel } from './CreateNewLocationModalPanel';
import { Edit, Trash } from 'iconsax-react';
import { IsTenantAdmin } from "../../molecules";

interface Props {
}

export const LocationListContent: FC<Props> = () => {
    const dispatch = useAppDispatch();
    const [tableData, setTableData] = useState<Location[]>([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching] = useState(false);
    const [rowCount] = useState(0);
    const [validationErrors, setValidationErrors] = useState<{
        [cellId: string]: string;
    }>({});
    const [selectedRow, setSelectedRow] = useState<MRT_Row<Location>>();
    const [showConfirm, setShowConfirm] = useState(false);
    const [showCreate, setShowCreate] = useState(false);
    const locations = useSelector(locationSelector);
    let authState = useSelector(authSelector);

    const getCommonEditTextFieldProps = useCallback(
        (
            cell: MRT_Cell<Location>,
        ): MRT_ColumnDef<Location>['muiEditTextFieldProps'] => {
            return {
                error: !!validationErrors[cell.id],
                helperText: validationErrors[cell.id],
                onBlur: (event) => {
                    const isValid =
                        cell.column.id === 'name'
                            ? validateRequired(event.target.value) : true;
                    if (!isValid) {
                        //set validation error for cell if invalid
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: `${cell.column.columnDef.header} is verplicht`,
                        });
                    } else {
                        //remove validation error for cell if valid
                        delete validationErrors[cell.id];
                        setValidationErrors({
                            ...validationErrors,
                        });
                    }
                },
            };
        },
        [validationErrors],
    );

    const columns = useMemo<MRT_ColumnDef<Location>[]>(
        () => [
            {
                header: t('fields.location.single.typeDescription'),
                accessorKey: 'name',
                muiEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
        ],
        [getCommonEditTextFieldProps],
    );

    const validateRequired = (value: string) => !!value.length;

    const handleSaveRowEdits: MRT_TableOptions<Location>['onEditingRowSave'] =
        async ({ exitEditingMode, row, values }) => {
            if (!Object.keys(validationErrors).length) {
                dispatch(updateLocation({location: values, jwtToken: authState.jwtToken}));
                tableData[row.index] = values;
                setTableData([...tableData]);
                exitEditingMode();
            }
        };

    const handleDeleteRow = useCallback(
        (row: MRT_Row<Location>) => {
            setSelectedRow(row);
            setShowConfirm(true);
        },
        [],
    );

    function handleConfirmDeleteRow() {
        if (tableData[selectedRow!.index].id) {
            dispatch(deleteLocation({locationId: tableData[selectedRow!.index].id!, jwtToken: authState.jwtToken}))
        }
        tableData.splice(selectedRow!.index, 1);
        setTableData([...tableData]);
        setShowConfirm(false);
        setSelectedRow(undefined);
    }

    function handleHideConfirm() {
        setShowConfirm(false);
        setSelectedRow(undefined);
    }

    const handleAddRow = (location: Location) => {
        dispatch(addLocation({location: location, jwtToken: authState.jwtToken}))
        tableData.push(location);
        setTableData([...tableData]);
    };

    useEffect(() => {
        setIsLoading(true);
        dispatch(getLocations({jwtToken: authState.jwtToken}))
            .then((response) => {
                if (response.type.includes('rejected')) {
                    setIsError(true);
                }})
            }, [authState.jwtToken, dispatch]);
    useEffect(() => {
        setIsLoading(false);
        setTableData([...locations.locations])
    }, [locations.locations])
    return (
        <Container>
            <MaterialReactTable
                columns={columns}
                data={tableData}
                enableEditing
                onEditingRowSave={handleSaveRowEdits}
                positionActionsColumn="last"
                enableRowSelection={false}
                enableColumnOrdering={false}
                enableGlobalFilter={false}
                enablePagination={false}
                enableColumnActions={false}
                muiToolbarAlertBannerProps={
                    isError
                        ? {
                            color: 'error',
                            children: t('error.retrievingData'),
                        }
                        : undefined
                }
                rowCount={rowCount}
                state={{
                    isLoading,
                    showAlertBanner: isError,
                    showProgressBars: isRefetching,
                }}
                renderRowActions={({ row, table }) => (
                    <IsTenantAdmin>
                        <Box sx={{ display: 'flex', gap: '1rem' }}>
                            <Tooltip arrow placement="left" title="Edit">
                                <IconButton color="primary" onClick={() => table.setEditingRow(row)}>
                                    <Edit />
                                </IconButton>
                            </Tooltip>
                            <Tooltip arrow placement="right" title="Delete">
                                <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                                    <Trash />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </IsTenantAdmin>
                )}
            />
            <ConfirmModalPanel title={`Weet je zeker dat je ${selectedRow?.getValue('name')} wilt verwijderen`} onConfirm={handleConfirmDeleteRow} onHide={handleHideConfirm} show={showConfirm}/>
            <CreateNewLocationModalPanel
                columns={columns}
                open={showCreate}
                onClose={() => setShowCreate(false)}
                onSubmit={handleAddRow}
            />
            <PrimaryButton sx={{mt: 2}} onClick={() => setShowCreate(true)} onlyVisibleForTenantAdmins={true}>{t('generic.add')}</PrimaryButton>
        </Container>
    )
};
