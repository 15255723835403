import React, { useState } from 'react';

// material-ui
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

// assets
import { Logout } from 'iconsax-react';

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

interface Props {
  handleLogout: () => void;
}

const ProfileTab = ({ handleLogout }: Props) => {
  const [selectedIndex] = useState(0);

  return (
    <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}>
      {/*<ListItemButton selected={selectedIndex === 0} onClick={(event: MouseEvent<HTMLDivElement>) => handleListItemClick(event, 0)}>*/}
      {/*  <ListItemIcon>*/}
      {/*    <Edit2 variant="Bulk" size={18} />*/}
      {/*  </ListItemIcon>*/}
      {/*  <ListItemText primary="Edit Profile" />*/}
      {/*</ListItemButton>*/}
      {/*<ListItemButton selected={selectedIndex === 1} onClick={(event: MouseEvent<HTMLDivElement>) => handleListItemClick(event, 1)}>*/}
      {/*  <ListItemIcon>*/}
      {/*    <Profile variant="Bulk" size={18} />*/}
      {/*  </ListItemIcon>*/}
      {/*  <ListItemText primary="View Profile" />*/}
      {/*</ListItemButton>*/}

      {/*<ListItemButton selected={selectedIndex === 3} onClick={(event: MouseEvent<HTMLDivElement>) => handleListItemClick(event, 3)}>*/}
      {/*  <ListItemIcon>*/}
      {/*    <Profile2User variant="Bulk" size={18} />*/}
      {/*  </ListItemIcon>*/}
      {/*  <ListItemText primary="Social Profile" />*/}
      {/*</ListItemButton>*/}
      {/*<ListItemButton selected={selectedIndex === 4} onClick={(event: MouseEvent<HTMLDivElement>) => handleListItemClick(event, 4)}>*/}
      {/*  <ListItemIcon>*/}
      {/*    <Card variant="Bulk" size={18} />*/}
      {/*  </ListItemIcon>*/}
      {/*  <ListItemText primary="Billing" />*/}
      {/*</ListItemButton>*/}
      <ListItemButton selected={selectedIndex === 2} onClick={handleLogout}>
        <ListItemIcon>
          <Logout variant="Bulk" size={18} />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>
    </List>
  );
};

export default ProfileTab;
