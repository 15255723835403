import React, { FC, useEffect, useState } from 'react';
import { authSelector, getServiceProfile, saveServiceProfile, serviceProfileSelector, useAppDispatch } from "../../../state";
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ErrorAlert } from '../../molecules';
import { Container } from 'react-bootstrap';
import { t } from 'i18next';
import MainCard from '../../molecules/MainCard';
import { Button, Grid, InputLabel, Stack, TextField } from '@mui/material';
import { ServiceProfileForm } from "../../../state/slices/serviceprofiles/ServiceProfileForm";


export const ServiceProfileDetailContent: FC = () => {
    const [serviceProfileForm, setServiceProfileForm] = useState<ServiceProfileForm>(new ServiceProfileForm());
    const dispatch = useAppDispatch();
    const {serviceProfileExternalKey} = useParams();
    let navigate = useNavigate();
    let authState = useSelector(authSelector);
    const serviceProfilesState = useSelector(serviceProfileSelector);

    useEffect(() => {
        if (serviceProfileExternalKey) {
            dispatch(getServiceProfile({
                jwtToken: authState.jwtToken!,
                serviceProfileExternalKey: serviceProfileExternalKey!
            }));
        }
        // eslint-disable-next-line
    }, [serviceProfileExternalKey, authState.jwtToken]);

    useEffect(() => {
        if (serviceProfilesState.singleItem && serviceProfilesState.singleItem.externalKey === serviceProfileExternalKey) {
            setServiceProfileForm(ServiceProfileForm.from(serviceProfilesState.singleItem));
        }
    }, [serviceProfilesState.singleItem, serviceProfilesState?.singleItem?.externalKey, serviceProfileExternalKey]);

    const handleSetFormValue = (param: any) => {
        setServiceProfileForm(Object.assign({}, serviceProfileForm, param));
    }

    function saveForm() {
        dispatch(saveServiceProfile({jwtToken: authState.jwtToken!, serviceProfileForm: serviceProfileForm}))
            .then((response) => {
                if (response.type.includes('fulfilled')) {
                    setTimeout(() => { // Use timeout the give time to update the redux store.
                        navigate('/manage/serviceprofiles')
                    }, 250);
                }
            });

    }

    function isFormValid() {
        return ServiceProfileForm.isValid(serviceProfileForm);
    }


    return (
        <Container>
            <ErrorAlert
                errorMessage={t('error.retrievingData')}
                show={serviceProfilesState.error !== undefined}/>
            <MainCard
                title={(serviceProfileExternalKey ? t('generic.form.edit') : t('generic.form.add')) + ' ' + t('fields.serviceProfile.single.typeDescription')}>
                <Grid item xs={12} md={8}>
                    <Grid item xs={12}>
                        <Stack spacing={1.25}>
                            <InputLabel htmlFor="title">{t('fields.serviceProfile.single.name')}</InputLabel>
                            <TextField type="text" rows={1} value={serviceProfileForm.name}
                                       onChange={e => handleSetFormValue({name: e.target.value})}
                                       inputProps={{maxLength: 100}}/>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} mt={3}>
                        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                            <Button variant="outlined" color="secondary"
                                    onClick={() => navigate(-1)}>{t('generic.back')}</Button>
                            <Button variant="contained" onClick={saveForm}
                                    disabled={serviceProfilesState.loading || !isFormValid()}>{t('generic.save')}</Button>
                        </Stack>
                    </Grid>
                </Grid>
            </MainCard>
        </Container>
    )


};
