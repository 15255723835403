import React, { FC } from 'react';
import { t } from 'i18next';
import { ListItemText, MenuItem, Select } from '@mui/material';

interface Props {
    selectedSupportType?: string;
    onSelect: (supportType: String) => void;
    disabled?: boolean;
}


export const SupportTypeCombo: FC<Props> = (props) => {

    return (
        <Select value={props.selectedSupportType ? props.selectedSupportType : ''}
                onChange={e => props.onSelect(e.target.value)}
                disabled={props.disabled}>
            <MenuItem key='CALL' value='CALL'><ListItemText primary={t('fields.supportType.CALL')} /></MenuItem>
            <MenuItem key='MAIL' value='MAIL'><ListItemText primary={t('fields.supportType.MAIL')} /></MenuItem>
            <MenuItem key='FAQ' value='FAQ'><ListItemText primary={t('fields.supportType.FAQ')} /></MenuItem>
        </Select>
    )
};

SupportTypeCombo.defaultProps = {
    disabled: false
}
