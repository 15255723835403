import { Service } from '../services';
import { Support } from './SupportSlice';


export class SupportForm {
    id?: number;
    parentId?: number;
    allServices: boolean = true;
    service?: Service;
    title?: string;
    body?: string;
    supportType: string = 'FAQ';
    pages: Support[] = [];
    static from(support: Support): SupportForm {
        return {
            id: support.id,
            parentId: support.parentId,
            allServices: (support.service === undefined),
            service: support.service,
            title: support.title,
            body: support.body,
            supportType: support.supportType,
            pages: support.pages
        };
    }
    static isValid(supportForm: SupportForm | undefined): boolean {
        if (supportForm === undefined) {
            return false;
        }

        function containsValue(value: any) {
            return value !== undefined && value !== null && (value.length === undefined || value.length > 0);
        }

        if (!containsValue(supportForm.title)) {
            return false;
        }

        if (!containsValue(supportForm.supportType)) {
            return false;
        }

        return true;
    }

}
