// material-ui
import { styled } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import React from "react";
import { useSelector } from "react-redux";
import { globalStateSelector } from "../../state";


export const Loader = () => {
  let globalState = useSelector(globalStateSelector).globalState;
  return (globalState.isLoading) ? (
        <LoaderWrapper>
          <LinearProgress color="primary" sx={{height: 2}}/>
        </LoaderWrapper>
    ) : null;
}

const LoaderWrapper = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 2001,
  width: '100%',
  '& > * + *': {
    marginTop: theme.spacing(2)
  }
}));
