import React, { FC } from 'react';
import { Row } from "react-bootstrap";
import styled from "styled-components";


interface CenteredMessageContainerProps {
    message: string
}

export const CenteredMessageContainer: FC<CenteredMessageContainerProps> = (props) => {
        return (
            <Container>
                <Row className="h-100 justify-content-center align-items-center">
                    <span>
                        {props.message}
                    </span>
                </Row>
            </Container>
        )
};


const Container = styled.div`
  height: 50vh;
`;

