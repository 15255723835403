import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// material-ui
import { Button, CircularProgress, Container, Grid, InputLabel, OutlinedInput, Stack } from '@mui/material';

// third-party
// project-imports
import { useAppDispatch } from '../../../state';
import { ErrorAlert } from "../../molecules";
import { t } from "i18next";
import MainCard from "../../molecules/MainCard";
import { saveSignup, SignupForm, signupSelector } from "../../../state/slices/signup";
import { useSelector } from "react-redux";

// assets

// ============================|| JWT - LOGIN ||============================ //

export const SignupFormContent = () => {
    const [signupForm, setSignupForm] = useState<SignupForm>(new SignupForm());
    let {subscriptionType} = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const signupState = useSelector(signupSelector);

    useEffect(() => handleSetFormValue({"subscriptionType": subscriptionType}),
    // eslint-disable-next-line
        [subscriptionType])

    function saveForm() {
        dispatch(saveSignup({signupForm: signupForm}))
            .then((response) => {
                if (response.type.includes('fulfilled')) {
                    setTimeout(() => { // Use timeout the give time to update the redux store.
                        navigate('/signup/checkemail')
                    }, 250);
                }
            });

    }

    function isFormValid() {
        return SignupForm.isValid(signupForm);
    }

    const handleSetFormValue = (param: any) => {
        setSignupForm(Object.assign({}, signupForm, param));
        // setSignupForm(Object.assign(signupForm, param));
    }

    return (
        <Container maxWidth="sm">
            <form noValidate>
                <ErrorAlert
                    errorMessage={t('error.retrievingData')}
                    errorCode={signupState.errorCode}
                    show={signupState.error !== undefined}/>
                <MainCard title={t('fields.signup.single.title')}
                          sx={{}}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel htmlFor="companyName">{t('fields.signup.single.companyName')}</InputLabel>
                                <OutlinedInput value={signupForm.companyName}
                                               name="companyName"
                                               onChange={e => handleSetFormValue({companyName: e.target.value})}/>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel
                                    htmlFor="contactPersonName">{t('fields.signup.single.contactPersonName')}</InputLabel>
                                <OutlinedInput value={signupForm.contactPersonName}
                                               autoComplete="name"
                                               name="given-name"
                                               onChange={e => handleSetFormValue({contactPersonName: e.target.value})}/>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel
                                    htmlFor="contactPersonEmailAddress">{t('fields.signup.single.contactPersonEmailAddress')}</InputLabel>
                                <OutlinedInput
                                    id="email-login"
                                    type="email"
                                    name="email"
                                    value={signupForm.contactPersonEmailAddress}
                                    autoComplete="username"
                                    onChange={e => handleSetFormValue({contactPersonEmailAddress: e.target.value})}/>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{pt: 4}}>
                        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                            { signupState.loading && (<CircularProgress />)}
                            <Button variant="contained" onClick={saveForm}
                                    disabled={signupState.loading || !isFormValid()}>{t('fields.signup.single.title')}</Button>
                        </Stack>
                    </Grid>
                </MainCard>
            </form>
        </Container>
    );
};
