import React from 'react';

// material-ui
import { Container, Grid } from '@mui/material';

// third-party
// project-imports
import { t } from "i18next";
import MainCard from "../../molecules/MainCard";

// assets

// ============================|| JWT - LOGIN ||============================ //

export const SignupCheckEmailContent = () => {

    return (
        <Container maxWidth="sm">
            <MainCard title={t('fields.signup.single.title')}
                      sx={{}}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        {t('signup.checkEmail')}
                    </Grid>
                </Grid>
            </MainCard>
        </Container>
    );
};
