import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Button, Grid, Link, OutlinedInput, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { authAuthenticate, authSelector, useAppDispatch } from '../../../state';
import AnimateButton from '../../atoms/AnimateButton';

interface Props {
}


export const AuthLogin: FC<Props> = () => {
    const dispatch = useAppDispatch();
    let navigate = useNavigate();
    const [code1, setCode1] = useState('');
    const [code2, setCode2] = useState('');
    const [code3, setCode3] = useState('');
    const [code4, setCode4] = useState('');
    const [code5, setCode5] = useState('');
    const [code6, setCode6] = useState('');
    let authState = useSelector(authSelector);
    const authCodeRef1 = React.createRef();
    const authCodeRef2 = React.createRef();
    const authCodeRef3 = React.createRef();
    const authCodeRef4 = React.createRef();
    const authCodeRef5 = React.createRef();
    const authCodeRef6 = React.createRef();

    const setCode = async (setAuthCode: (value: (((prevState: string) => string) | string)) => void, e: any, nextElement: any) => {
        e.preventDefault();
        const value = e.target.value;
        // Prevent non digits
        const result = value.replace(/\D/g, '');
        setAuthCode(result);
        if (nextElement === undefined) {
            handleSubmitCode(code1 + code2 + code3 + code4 + code5 + result);
        } else if (result.length > 0) {
            nextElement.current.select();
            nextElement.current.focus();
        }
    };
    const onPasteCode = async (e: any) => {
        const clipBoardContent = e.clipboardData.getData('Text');
        if (clipBoardContent && clipBoardContent.length === 6 && !isNaN(Number(clipBoardContent.toString()))) {
            setCode1(clipBoardContent[0]);
            setCode2(clipBoardContent[1]);
            setCode3(clipBoardContent[2]);
            setCode4(clipBoardContent[3]);
            setCode5(clipBoardContent[4]);
            setCode6(clipBoardContent[5]);
            handleSubmitCode(clipBoardContent);
        }
    }

    function handleSubmit() {
        handleSubmitCode(code1 + code2 + code3 + code4 + code5 + code6);
    }
    function handleSubmitCode(authenticationCode: string) {
        console.log(authState);
        dispatch(authAuthenticate({
            emailAddress: '' + authState.emailAddress,
            authenticationCode: authenticationCode
        }))
            .then((value) => {
                    if (value.type.includes('fulfilled')) {
                        navigate('/authenticate');
                    }
                    navigate('/');
                }
            );
    }

    function handleSubmitButton(event: React.SyntheticEvent) {
        event.preventDefault();
        handleSubmit();
    }



    return (
        <form noValidate onSubmit={handleSubmitButton}>
            Voer de code in die u in uw e-mail heeft ontvangen.
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Stack spacing={1}>
                        <DigitGrid as={Grid} mt={5}>
                            <InputCode type="text" inputProps={{ maxLength: 1 }}
                                       autoFocus
                                       inputRef={authCodeRef1}
                                       value={code1}
                                       onPaste={onPasteCode}
                                       onChange={(e: any) => setCode(setCode1, e, authCodeRef2)}/>
                            <InputCode type="text" inputProps={{ maxLength: 1 }}
                                       inputRef={authCodeRef2}
                                       value={code2}
                                       onPaste={onPasteCode}
                                       onChange={(e: any) => setCode(setCode2, e, authCodeRef3)}/>
                            <InputCode type="text" inputProps={{ maxLength: 1 }}
                                       inputRef={authCodeRef3}
                                       value={code3}
                                       onPaste={onPasteCode}
                                       onChange={(e: any) => setCode(setCode3, e, authCodeRef4)}/>
                            <InputCode type="text" inputProps={{ maxLength: 1 }}
                                       inputRef={authCodeRef4}
                                       value={code4}
                                       onPaste={onPasteCode}
                                       onChange={(e: any) => setCode(setCode4, e, authCodeRef5)}/>
                            <InputCode type="text" inputProps={{ maxLength: 1 }}
                                       inputRef={authCodeRef5}
                                       value={code5}
                                       onPaste={onPasteCode}
                                       onChange={(e: any) => setCode(setCode5, e, authCodeRef6)}/>
                            <InputCode type="text" inputProps={{ maxLength: 1 }}
                                       inputRef={authCodeRef6}
                                       value={code6}
                                       onPaste={onPasteCode}
                                       onChange={(e: any) => setCode(setCode6, e, undefined)}/>
                        </DigitGrid>                    </Stack>
                </Grid>

                <Grid item xs={12} sx={{ mt: -1 }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                        <Link variant="h6" component={RouterLink} to="/forgot-password" color="text.primary">
                            Heeft u geen code ontvangen?
                        </Link>
                    </Stack>
                </Grid>
                {/*{errors.submit && (*/}
                {/*    <Grid item xs={12}>*/}
                {/*        <FormHelperText error>{errors.submit}</FormHelperText>*/}
                {/*    </Grid>*/}
                {/*)}*/}
                <Grid item xs={12}>
                    <AnimateButton>
                        <Button disableElevation fullWidth size="large" type="submit" variant="contained" color="primary">
                            Login
                        </Button>
                    </AnimateButton>
                </Grid>
            </Grid>
        </form>
    )


};

const InputCode = styled(OutlinedInput)`
    margin: 10px;
    padding:5px; 
    width: 50px;
    height: 50px;
    text-align: center; 
    -webkit-appearance: none;
    -moz-appearance: textfield;
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
`;

const DigitGrid = styled(Grid)`
    display: flex;
    justify-content: center; 
`;
