import React, { FC, useEffect, useMemo, useState } from 'react';
import { authSelector, getImpactTypes, ImpactType, impactTypeSelector, saveImpactTypeDisplayOrder, useAppDispatch } from "../../../state";
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import { ErrorAlert } from '../../molecules';
import { t } from 'i18next';
import type { MRT_ColumnDef, MRT_Row } from 'material-react-table';
import { MaterialReactTable } from 'material-react-table';
import type { ColumnFiltersState, PaginationState, SortingState, } from '@tanstack/react-table';
import { getSortParameters, OrderEnum } from '../../utils';
import { useNavigate, useParams } from 'react-router-dom';
import { PrimaryButton } from '../../atoms';

interface Props {
}

export const ImpactTypeListContent: FC<Props> = () => {
    //table state
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting] = useState<SortingState>([]);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });
    let {serviceExternalKey, reportedAtFrom, reportedAtUntil} = useParams();
    const dispatch = useAppDispatch();
    let navigate = useNavigate();
    const impactTypesState = useSelector(impactTypeSelector);
    let authState = useSelector(authSelector);


    const columns = useMemo<MRT_ColumnDef<ImpactType>[]>(
        () => [
            {
                header: t('fields.impactType.single.description'),
                accessorKey: 'description',
                minSize: 50,
                maxSize: 150,
            },
            {
                header: t('fields.impactType.single.logo'),
                accessorKey: 'logo',
                accessorFn: (originalRow) => (<img width="50" src={originalRow.logo} alt="Logo"/>)
            },
        ],
        [],
    );
    let sortParameters = getSortParameters(sorting, {field: 'displayOrder', order: OrderEnum.asc});

    useEffect(() => {

        dispatch(getImpactTypes({
            jwtToken: authState.jwtToken!,
            page: pagination.pageIndex,
            pageSize: pagination.pageSize,
            sortParameters
        }))
    }, [
        dispatch,
        authState.jwtToken,
        sortParameters,
        columnFilters,
        globalFilter,
        pagination.pageIndex,
        pagination.pageSize,
        sorting,
        serviceExternalKey,
        reportedAtFrom,
        reportedAtUntil
    ]);

    function add() {
        navigate('/manage/impacttypes/add');
    }

    return (
        <Container>
            <ErrorAlert
                errorMessage={t('error.retrievingData')}
                show={impactTypesState.error !== undefined}/>

            <MaterialReactTable
                columns={columns}
                data={impactTypesState.list}
                manualFiltering
                manualPagination
                enableRowSelection={false}
                enableGlobalFilter={false}
                enableColumnFilters={false}
                enableColumnOrdering={false}
                enableFullScreenToggle={false}
                enableDensityToggle={false}
                enableHiding={false}
                enableTopToolbar={false}
                enableSorting={false}
                enableColumnActions={false}
                onColumnFiltersChange={setColumnFilters}
                onGlobalFilterChange={setGlobalFilter}
                onPaginationChange={setPagination}
                rowCount={impactTypesState.totalElements}
                state={{
                    columnFilters,
                    globalFilter,
                    pagination,
                    sorting
                }}
                muiTableBodyRowProps={({row}) => (authState.isTenantAdmin) ? {
                    onClick: (event) => {
                        navigate('/manage/impacttypes/' + row.original.id);
                    },
                    sx: {
                        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                    },
                } : {}
                }
                enableRowOrdering={authState.isTenantAdmin}
                muiRowDragHandleProps={({table}) => ({
                        onDragEnd: () => {
                            const {draggingRow, hoveredRow} = table.getState();
                            if (hoveredRow && draggingRow) {
                                dispatch(saveImpactTypeDisplayOrder({
                                    jwtToken: authState.jwtToken!,
                                    page: pagination.pageIndex,
                                    pageSize: pagination.pageSize,
                                    sortParameters,
                                    impactTypeId: (draggingRow as MRT_Row<ImpactType>).original.id,
                                    newDisplayOrder: (hoveredRow as MRT_Row<ImpactType>).original.displayOrder
                                }));
                            }
                        }
                    }
                )}
            />
            <PrimaryButton sx={{mt: 2}} onClick={add} onlyVisibleForTenantAdmins={true}>{t('generic.add')}</PrimaryButton>
        </Container>
    )


};
