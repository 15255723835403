import React, { FC, useEffect, useMemo } from 'react';
import { authSelector, getServiceProfiles, ServiceProfile, serviceProfileSelector, useAppDispatch } from "../../../state";
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import { ErrorAlert } from '../../molecules';
import { t } from 'i18next';
import type { MRT_ColumnDef } from 'material-react-table';
import { MaterialReactTable } from 'material-react-table';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from '../../atoms/PrimaryButton';

interface Props {
}

export const ServiceProfilesListContent: FC<Props> = () => {
    const dispatch = useAppDispatch();
    const serviceProfiles = useSelector(serviceProfileSelector);
    let authState = useSelector(authSelector);
    let navigate = useNavigate();

    const columns = useMemo<MRT_ColumnDef<ServiceProfile>[]>(
        () => [
            {
                header: t('fields.serviceProfile.single.name'),
                accessorKey: 'name',
            },
        ],
        [],
    );
    useEffect(() => {
        dispatch(getServiceProfiles({jwtToken: authState.jwtToken}));
        // eslint-disable-next-line
    }, [authState.jwtToken]);

    return (
        <Container>
            <ErrorAlert
                errorMessage={t('error.retrievingData')}
                show={serviceProfiles.error !== undefined}/>

            <MaterialReactTable
                columns={columns}
                data={serviceProfiles.list}
                enableRowSelection={false}
                enableGlobalFilter={false}
                enableColumnFilters={false}
                enableColumnOrdering={false}
                enableFullScreenToggle={false}
                enableDensityToggle={false}
                enableHiding={false}
                enableTopToolbar={false}
                enableSorting={false}
                enableColumnActions={false}
                muiTableBodyRowProps={({row}) => (authState.isTenantAdmin) ? {
                    onClick: (event) => {
                        navigate('/manage/serviceprofile/' + row.original.externalKey);
                    },
                    sx: {
                        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                    },
                } : {}}

            />
            <PrimaryButton sx={{mt: 2}} onClick={() => {navigate('/manage/serviceprofile/add');}} onlyVisibleForTenantAdmins={true}>{t('generic.add')}</PrimaryButton>
        </Container>
    )


};
