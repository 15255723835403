import React, { FC } from 'react';
import { t } from 'i18next';
import { ImpactType, IssueType } from '../../state';
import { ListItemText, MenuItem, Select } from '@mui/material';

interface Props {
    selectedIssueTypeId?: number;
    onSelect: (issueType: IssueType) => void;
    issueTypes?: IssueType[];
    disabled?: boolean
}


export const IssueTypeSelectCombo: FC<Props> = (props) => {
    function onSelectItem(id: string|number) {
        props.onSelect(getSelectedIssueType(id));
    }

    function getSelectedIssueType(id: string|number):ImpactType {
        const selectedId = Number(id);
        return props.issueTypes!.filter((issueType) => issueType.id === selectedId)[0];
    }

    function getIssueTypeLabel(selectedId: number|string):string {
        const issueType = getSelectedIssueType(selectedId);
        return issueType.description;
    }

    return (
        <Select value={props.selectedIssueTypeId ? props.selectedIssueTypeId: ''}
                displayEmpty
                onChange={e => onSelectItem(e.target.value)}
                disabled={props.disabled}
                renderValue={(selected) => {
                    if (!selected) {
                        return <ListItemText primary={t('fields.issueType.single.selectA')}/>;
                    }

                    return <ListItemText primary={getIssueTypeLabel(selected)}/>;
                }}>
            {props.issueTypes?.map((issueType) => (
                <MenuItem key={issueType.id} value={issueType.id}>
                    <ListItemText primary={issueType.description} />
                </MenuItem>))}
        </Select>
    )
};

IssueTypeSelectCombo.defaultProps = {
    disabled: false
}
