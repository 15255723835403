import React, { FC, useEffect, useState } from 'react';
import { authSelector, disruptionsSelector, getDisruption, getServices, saveDisruption, servicesSelector, useAppDispatch } from "../../../state";
import { useSelector } from 'react-redux';
import { Container, Form } from 'react-bootstrap';
import { DateTimeInput, ErrorAlert, ImpactTypeSelectCombo, IssueTypeSelectCombo, ServiceSelectCombo } from '../../molecules';
import { t } from 'i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { DisruptionForm } from '../../../state/slices/disruptions/DisruptionForm';
import { Button, FormControl, Grid, InputLabel, Stack, TextField } from '@mui/material';
import MainCard from '../../molecules/MainCard';


interface Props {
}

export const DisruptionDetailContent: FC<Props> = () => {
    const [disruptionForm, setDisruptionForm] = useState<DisruptionForm>(new DisruptionForm());
    const dispatch = useAppDispatch();
    const {disruptionId} = useParams();
    const disruptionState = useSelector(disruptionsSelector);
    let navigate = useNavigate();
    let authState = useSelector(authSelector);
    const services = useSelector(servicesSelector);

    useEffect(() => {
        dispatch(getServices({jwtToken: authState.jwtToken!}));
    }, [dispatch, authState.jwtToken]);
    useEffect(() => {
        if (disruptionId) {
            dispatch(getDisruption({jwtToken: authState.jwtToken!, disruptionId: disruptionId}));
        }
    }, [dispatch, authState.jwtToken, disruptionId]);
    useEffect(() => {
        if (disruptionState.singleItem && disruptionState.singleItem.id === Number(disruptionId)) {
            setDisruptionForm(DisruptionForm.from(disruptionState.singleItem));
        }
    }, [disruptionState.singleItem, disruptionId]);


    const handleSetFormValue = (param: any) => {
        setDisruptionForm(Object.assign({}, disruptionForm, param));
    }

    function saveForm() {
        dispatch(saveDisruption({jwtToken: authState.jwtToken!, disruptionForm: disruptionForm}))
            .then((response) => {
                if (response.type.includes('fulfilled')) {
                    setTimeout(() => { // Use timeout the give time to update the redux store.
                        navigate('/manage/disruptions/list')
                    }, 250);
                }
            });

    }

    function isFormValid() {
        return DisruptionForm.isValid(disruptionForm);
    }

    return (
        <Container>
            <ErrorAlert
                errorMessage={t('error.retrievingData')}
                show={disruptionState.error !== undefined}/>
            <MainCard title={disruptionId ? 'Wijzig verstoring': 'Voeg verstoring toe'}>
                <Grid item xs={12} md={8}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel htmlFor="service">{t('fields.service.single.typeDescription')}</InputLabel>
                                <FormControl fullWidth={false}>
                                    <ServiceSelectCombo onSelect={(service) => handleSetFormValue({service: service})}
                                                selectedServiceExternalKey={disruptionForm?.service?.externalKey} services={services.list}/>
                                </FormControl>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel htmlFor="description">{t('fields.disruption.single.description')}</InputLabel>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={5}
                                    defaultValue={disruptionForm.description}
                                    id="description"
                                    placeholder="Omschrijving"
                                    onChange={e => handleSetFormValue({description: e.target.value})}
                                />
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel htmlFor="issueType">{t('fields.issueType.single.typeDescription')}</InputLabel>
                                <FormControl fullWidth={false}>
                                    <IssueTypeSelectCombo onSelect={(issueType) => handleSetFormValue({dominantIssueTypeId: issueType.id})}
                                                      selectedIssueTypeId={disruptionForm?.dominantIssueTypeId} issueTypes={disruptionForm?.service?.allowedIssueTypes}
                                                      disabled={disruptionForm?.service === undefined}  />
                                </FormControl>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel htmlFor="impactType">{t('fields.impactType.single.typeDescription')}</InputLabel>
                                <FormControl fullWidth={false}>
                                    <ImpactTypeSelectCombo onSelect={(impactType) => handleSetFormValue({dominantImpactTypeId: impactType.id})}
                                                       selectedImpactTypeId={disruptionForm?.dominantImpactTypeId} impactTypes={disruptionForm?.service?.allowedImpactTypes}
                                                       disabled={disruptionForm?.service === undefined}/>
                                </FormControl>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel htmlFor="startedAt">{t('fields.disruption.single.startedAt')}</InputLabel>
                                <DateTimeInput value={disruptionForm?.startedAt} onChange={(value) => handleSetFormValue({startedAt: value})}
                                               disabled={disruptionForm?.service === undefined}/>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <InputLabel htmlFor="endedAt">{t('fields.disruption.single.endedAt')}</InputLabel>
                                <DateTimeInput value={disruptionForm?.endedAt} onChange={(value) => handleSetFormValue({endedAt: value})}
                                               disabled={disruptionForm?.service === undefined}/>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack spacing={1.25}>
                                <Form.Check
                                    type='checkbox'
                                    id='notifyUser'
                                    label={t('fields.disruption.single.notifyUsers')}
                                    onChange={e => handleSetFormValue({notifyUsers: e.target.checked})}
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                            <Button variant="outlined" color="secondary" onClick={() => navigate(-1)}>
                                Cancel
                            </Button>
                            <Button variant="contained" onClick={saveForm} disabled={disruptionState.loading || !isFormValid()}>{t('generic.save')}</Button>
                        </Stack>
                    </Grid>
                </Grid>
            </MainCard>
        </Container>
    )
};
